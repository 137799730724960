import Axios                from "axios";
import React                from "react";
import {render}             from "react-dom";
import JWT                  from "jsonwebtoken";
import php_date             from 'locutus/php/datetime/date';
import {cluster}            from "Cheops/../clusters/current";
import {ErrorPage4xx}       from "../error_pages/ErrorPage/ErrorPage4xx";


if (typeof CONFIG === 'undefined') {
    global.CONFIG = window.CONFIG;
}

export function date(format, timestamp) {

    timestamp = timestamp || (new Date()).getTime() / 1000;


    let full_month_arr = {
        1: "января",
        2: "февраля",
        3: "марта",
        4: "апреля",
        5: "мая",
        6: "июня",
        7: "июля",
        8: "августа",
        9: "сентября",
        10: "октября",
        11: "ноября",
        12: "декабря"
    };
    let short_month_arr = {
        1: "янв",
        2: "фев",
        3: "мар",
        4: "апр",
        5: "май",
        6: "июн",
        7: "июл",
        8: "авг",
        9: "сен",
        10: "окт",
        11: "ноя",
        12: "дек"
    };
    let full_weekday_arr = ["воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"];
    let short_weekday_arr = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];


    let full_month = full_month_arr[php_date("n", timestamp)];
    let short_month = short_month_arr[php_date("n", timestamp)];
    let full_weekday = full_weekday_arr[php_date("w", timestamp)];
    let short_weekday = short_weekday_arr[php_date("w", timestamp)];

    format = format.replace('F', full_month);
    format = format.replace('M', short_month);
    format = format.replace('l', full_weekday);
    format = format.replace('D', short_weekday);

    return php_date(format, timestamp);
}


/**
 * @deprecated use date instead
 * @param date
 * @param date_only
 * @param short_format
 * @returns {string}
 */
export function formatDate(date, date_only, short_format) {

    date_only = date_only | false;
    short_format = short_format | false;

    var monthNames = [
        "января", "февраля", "марта",
        "апреля", "мая", "июня", "июля",
        "августа", "сентября", "октября",
        "ноября", "декабря"
    ];

    var time = ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    var day_with_zero = ("0" + day).slice(-2);
    var month_with_zero = ("0" + (monthIndex + 1)).slice(-2);

    if (short_format) {
        return day_with_zero + '.' + month_with_zero + " " + time;
    }

    if (date_only) {
        return day + ' ' + monthNames[monthIndex] + ' ' + year + ' года';
    }
    return time + ' ' + day + ' ' + monthNames[monthIndex] + ' ' + year + ' года';

}


export function formatPlural(n, pluralForms) {

    if (n % 10 == 1 && n % 100 != 11) {
        return pluralForms[0];
    }
    if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
        return pluralForms[1];
    }
    return pluralForms[2];

}


let user_server_date_diff = 0;

export async function syncServerTime() {
    try {
        let result = await Axios({
            method: "POST",
            url: "/smt-cache/time",
            headers: {
                'content-type': 'application/json',
                'Accept': 'application/json'
            }
        });
        let server_date = new Date(result.data.time);
        user_server_date_diff = server_date - (new Date());
    } catch (e) {
        console.error("ERROR syncServerTime");
        console.error(e);
    }
}

export function getNowDate() {

    let now = new Date();

    let server_now = now.getTime() + user_server_date_diff;
    return new Date(server_now);
}

export function setContestLoginData(contestId, sessionId, userToken) {
    localStorage.setItem('liContestId', contestId);
    localStorage.setItem('liSessionId', sessionId);
    if (!userToken) {
        localStorage.removeItem('user_token')
    } else {
        localStorage.setItem('user_token', userToken);
    }
}

export function getContestIdAndSesionId() {


    if (CONFIG.IsNoopolis) {
        let url_matches = location.hash.match(/contest\/(\d+)\/(\d+)$/);
        let auth_token = localStorage.getItem('auth_token');

        if (url_matches) {

            return {
                contestId: url_matches[2],
                sessionId: auth_token,
            }
        }

        location.href = CONFIG.Noopolis.url + "/#/course/" + url_matches[1] + "/final_test";
        return;
    }


    let contestId = localStorage.getItem('liContestId');
    let sessionId = localStorage.getItem('liSessionId');

    let url_matches = location.pathname.match(/regQuizId=(\d+)&sid=([^=]+)/);

    if (url_matches) {
        contestId = url_matches[1];
        sessionId = url_matches[2];

        setContestLoginData(contestId, sessionId);
    }

    return {
        contestId: contestId,
        sessionId: sessionId,
    }
}

export function removeContestIdAndSessionId() {
    localStorage.removeItem('liContestId');
    localStorage.removeItem('liSessionId');
    localStorage.removeItem('user_token');
}


Object.compare = function (obj1, obj2) {
    //Loop through properties in object 1
    for (let p in obj1) {

        if (obj1 && (obj2 === null || obj2 === undefined)) {

            return false;

        }

        //Check property exists on both objects
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

        switch (typeof (obj1[p])) {
            //Deep compare objects
            case 'object':
                if (obj1[p] === null) {

                    if (obj2[p] !== null) {

                        return false;

                    }

                    break;

                }

                if (!Object.compare(obj1[p], obj2[p])) return false;
                break;
            //Compare function code
            case 'function':
                if (typeof (obj2[p]) == 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;
                break;
            //Compare values
            default:
                if (obj1[p] !== obj2[p]) return false;
        }
    }

    //Check object 2 for any extra properties
    for (let p in obj2) {
        if (typeof (obj1[p]) == 'undefined') return false;
    }
    return true;
};
/*
if (Array.prototype.equals)
    console.warn("Overriding existing Array.prototype.equals.");
// attach the .equals method to Array's prototype to call it on any array
Array.prototype.equals = function (array, ignore_sort) {


    ignore_sort = ignore_sort | false;

    // if the other array is a falsy value, return
    if (!array)
        return false;

    // compare lengths - can save a lot of time
    if (this.length != array.length)
        return false;

    for (var i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i], ignore_sort))
                return false;
        } else {
            if (ignore_sort && this.indexOf(array[i]) === -1) {
                return false;
            } else if (!ignore_sort && this[i] != array[i]) {
                // Warning - two different object instances will never be equal: {x:20} != {x:20}
                return false;
            }
        }
    }
    return true;
};*/
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", {enumerable: false});


export function getUserInfoFromJWT(token_field) {

    let token = localStorage.getItem('auth_token');

    if (!token) {
        return null;
    }

    let jwt_decoded = JWT.decode(token);

    if (!jwt_decoded || typeof jwt_decoded[token_field] === 'undefined') {
        return null;
    }

    return jwt_decoded[token_field];
}

export function renderParentModeBar() {


    let bar_el = document.querySelector('.parent_mode_text');

    if (bar_el) {
        bar_el.parentNode.removeChild(bar_el);
    }

    let roles = getUserInfoFromJWT('roles');

    if (roles && roles.indexOf('nooRO') !== -1) {
        document.getElementById("index").insertAdjacentHTML(
            'afterend',
            `<div class="parent_mode_text">${cluster.parent.mode_text}</div>`
        );
    }

}

export function renderErrorPage(error_code) {

    if ([408, 409, 423, 429].includes(error_code)) {

        render(
            <ErrorPage4xx statusCode={error_code}/>,
            document.getElementById("index"),
        );

        return;

    }

    if (![403, 404, 500, 600].includes(error_code)) {

        throw  Error('unexpected error code');

    }

    Axios.get(`/${error_code}.html`)
        .then(
            ({data}) => {
                document.open();
                document.write(data);
                document.close();
            }
        )
        .finally( () =>
            window.addEventListener('hashchange', () => {
                location.reload();
            }, false)
        )
    ;




}

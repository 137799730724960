import React, {FC, useEffect, useState}                    from "react";
import ReactDOM                                            from "react-dom";
import bnc                                                 from "bnc";
import {Screen, VBox}                                      from "@sirius/ui-lib/src/blocks/Layout";
import {CircularProgress}                                  from "@sirius/ui-lib/src/blocks/CircularProgress";
import LangSwitcher                                        from "@sirius/ui-lib/src/blocks/LangSwitcher/LangSwitcher";
import IndexPage                                           from "Smt/IndexPage/IndexPage";
import {postTokenFillFormRegister, postTokenLoginRegister} from "Smt/actions/webPortal";
import {setContestLoginData}                               from "Smt/helpers";
import {DocumentTitleLocale}                               from "Smt/SmtLocaleBlock/DocumentTitleLocale";
import {SmtLocaleString}                                   from "Smt/SmtLocaleBlock";
import {UserForm}                                          from "./Form";
import {LoginInfo, LoginStage}                             from "./@types";



const block = new bnc("smt-login-page");
const DN = (CONFIG?.DeployName || '').toLowerCase() === 'cpm' ? 'CPM' : 'SIRIUS';

const setTitle = (contestName: string) => {
    const isContestName = typeof contestName === 'string' && contestName.length > 0;
    const title = (
        <>
            <SmtLocaleString k={`common.login_page.${isContestName ? 'welcome' : 'title'}`}  />
            { isContestName &&
                <>
                    <br />
                    {contestName}
                </>
            }
        </>
    );
    const rowSize = isContestName ? 'small' : 'normal';

    return {title, rowSize};
};

const getSearchParam = (paramName: string, {search}: Location) => {
    const urlSearchParams = new URLSearchParams(search);

    return urlSearchParams.has(paramName)
        ? urlSearchParams.get(paramName)
        : null
};

const setRegistration = ({liContestId, liSessionId}: SmtPortal.Methods.LoginInfo) => {
    setContestLoginData(liContestId, liSessionId);

    if (location.search !== '') {
        location.href = location.origin + location.pathname;
        return false;
    }

    ReactDOM.render(<IndexPage />, document.getElementById("index"));
};


export const LoginPage:FC = () => {

    const [loginInfo,  setLoginInfo ] = useState<LoginInfo>({});
    const [loginStage, setLoginStage] = useState<LoginStage>('userParticipantCode');
    const [hasErrors,  setHasErrors ] = useState<boolean>(false);
    const [loading,    setLoading   ] = useState<boolean>(false);

    useEffect(() => {
            const participantCode = getSearchParam('user_code', location);
            if (participantCode !== null) {
                onRegister({participantCode});
            }
        },
        [location]
    );

    const { loginNeedInfo } = loginInfo;
    const { contestName, schoolName, form: schema } = loginNeedInfo ?? {};
    const { title, rowSize } = setTitle(contestName);

    const onRegister = ({participantCode, ...value}: any) => {
        const token = participantCode ?? loginInfo.token;
        setLoading(true);

        loginStage === 'userDetailInfo'
            ? postTokenFillFormRegister({token, value})
                .then(
                    (result) => {
                        if ('data' in result) {
                            setRegistration( result.data );
                        }
                    },
                    (error) => {
                        if (error.response.status === 401) {
                            setHasErrors(true);
                        }
                    },
                )
                .finally(() => setLoading(false) )

            : postTokenLoginRegister({token})
                .then(
                    ( {data} ) => {
                        if ('loginNeedInfo' in data) {
                            const {loginNeedInfo} = data;

                            setLoginInfo ({
                                token,
                                loginNeedInfo
                            });
                            setLoginStage('userDetailInfo');
                            setHasErrors(false);
                            setLoading(false);

                        } else if ('canPassTest' in data) {
                            setRegistration(data.canPassTest);
                        }
                    },
                    (error) => {
                        if (error.response.status === 401) {
                            setHasErrors(true);
                            setLoading(false);
                        }
                    },
                )
        ;
    };

    return (
        loading
            ?  <CircularProgress centerOfWindow={true} />
            :  <Screen className={block}>
                    <DocumentTitleLocale k="common.document.default_title" defaultTitle={document.title} />
                    <LangSwitcher />
                    <VBox className={block.el('content')} >
                        <div className={block.el('gerb')} />
                        <div className={block.el('dep')}>
                            <SmtLocaleString
                                k={`common.login_page.header_${DN}`}
                                values={{br: <br />}}
                            />
                        </div>
                        <div className={block.el('title') + block.el('title').mod(rowSize)}>
                            {title}
                        </div>
                        { schoolName &&
                            <div className={block.el('school')}>
                                {schoolName}
                            </div>
                        }
                        <UserForm
                            schema     = {schema}
                            loginStage = {loginStage}
                            onRegister = {onRegister}
                            hasErrors  = {hasErrors}
                        />
                    </VBox>
                    <VBox className={block.el('footer')}>
                        <SmtLocaleString
                            k={`${CONFIG.currentInstance}.index.copyright_${DN}`}
                            values={{currentYear: new Date().getFullYear(), br: <br />}}
                        />
                    </VBox>
                </Screen>
    );
}

import {translations as AuthRegisterTranslations }  from "./Auth/AuthRegister/translations";
import {translations as LoginPageTranslations }     from "./Auth/LoginPage/Form/translations";
import {reviewResult, reviewStatus}                 from "@sirius/ui-shared/src/Translations/Programming";

export type TranslationsType = {
    [key: string]: Project;
};

type Project = {
    [key: string]: Record<string, any>;
};

export const translations: TranslationsType = {
    storage:{
        errors:{
            unknown_content_type: {
                ru: {value: "Неверный формат файла"},
                en: {value: "Wrong content type"},
            },
            file_too_big: {
                ru: {value: "Невозможно загрузить файл, попробуйте уменьшить его размер"},
                en: {value: "It's impossible to upload the file, try to reduce its size"}
            },
            minio_error: {
                ru: {value: "Не удалось загрузить файл. Попробуйте обновить страницу или загрузите файл позже"},
                en: {value: "The file could not be uploaded. Try refreshing the page or upload the file later"}
            },
        }
    },
    latex: {
        errors: {
            too_long: {
                ru: {value: "Кажется, вы ошиблись. Ответ должен быть короче"},
                en: {value: "It seems answer must take less symbol"},
            },
            cant_parse: {
                ru: {value: "Мы не можем понять запись"},
                en: {value: "We can't recognize your input"},
            },
            convert: {
                ru: {value: "Ошибка при конвертации"},
                en: {value: "Convertation error"},
            },
        }
    },
    olympiad: {
        "task-page-tab": {
            "no-result-warning": {
                ru: {value:"Отправьте хотя бы одно решение,{br}чтобы стать участником рейтинга"},
                en: {value:"Submit at least one solution {br}to become a participant of the rating"},
            },
            "no-filtered-result": {
                ru: {value:"Ничего не найдено"},
                en: {value:"Nothing found"},
            },
            "no-result": {
                ru: {value:"Ещё ни один участник не отправил решение на проверку.{br}Вы можете стать первым."},
                en: {value:"No participant has submitted a solution for review yet. {br}You can be the first."},
            },
            "refresh": {
                ru: {value:"Обновить"},
                en: {value:"Update"},
            },
            "numtasklabel": {
                ru: {value:"Задачи"},
                en: {value:"Tasks"},
            },
            "place": {
                ru: {value:"Место"},
                en: {value:"Place"},
            },
            "amount": {
                ru: {value:"Сумма"},
                en: {value:"Amount"},
            },
            "participant": {
                ru: {value:"Участник"},
                en: {value:"Participant"},
            },
            "your-position": {
                ru: {value:"Ваша позиция"},
                en: {value:"Your position"},
            },
            "all-participants": {
                ru: {value:"Все участники"},
                en: {value:"All participants"},
            },
            participants: {
                ru: {value:"Участники"},
                en: {value:"Participants"},
            },
            tasks: {
                ru: {value:"Задачи"},
                en: {value:"Tasks"},
            },
        },
        start_button: {
            took_part: {
                ru: {value: "Вы приняли участие в олимпиаде."},
                en: {value: "You took part in the olympiad."},
            },
            ended: {
                ru: {value: "Олимпиада завершена."},
                en: {value: "The olympiad is over."},
            },
            stopped: {
                ru: {value: "Олимпиада временно приостановлена."},
                en: {value: "The olympiad is temporarily suspended."},
            },
            dont_took_part: {
                ru: {value: "Вы не принимали в ней участие."},
                en: {value: "You didn't take part in it."},
            },
            not_yet_started: {
                ru: {value: "Олимпиада начнётся"},
                en: {value: "The olympiad starts"},
            },
            not_yet_started_date: {
                ru: {
                    value: "Олимпиада начнётся {date}",
                },
                en: {
                    value: "The olympiad starts on {date}",
                },
            },
            not_yet_started_time: {
                ru: {
                    value: "в {date} на этой странице",
                },
                en: {
                    value: "at {date} on this page",
                },
            },
        },
        user_form: {
            go_btn: {
                ru: {value: "Перейти к олимпиаде"},
                en: {value: "To the olympiad"},
            },
        },
        certificate: {
            back: {
                ru: {value: "Вернуться к олимпиаде"},
                en: {value: "Return to the olympiad"},
            },
            share: {
                ru: {value: "Поделитесь результатом, который вы показали на олимпиаде"},
                en: {value: "Share your olympiad result"},
            },
        },
        status_row: {
            ended: {
                ru: {value: "Завершилась"},
                en: {value: "Is over"},
            },
            not_yet_started: {
                ru: {value: "Будет доступна"},
                en: {value: "Will be available"},
            },
            not_yet_started_date: {
                ru: {
                    value: "c {date}",
                },
                en: {
                    value: "from {date}",
                },
            },
        },
        index: {
            copyright_CPM: {
                ru: {value: `© ГАОУ ДПО ЦПМ, 2018–{currentYear} {br} © Фонд «Талант и успех», 2018–{currentYear}`},
                en: {value: `© Center of Pedagogical Excellence, 2018–{currentYear} {br} © Fund “Talent and success”, 2018–{currentYear}`},
            },
            copyright_SIRIUS: {
                ru: {value: `© Фонд «Талант и успех», 2018–{currentYear}`},
                en: {value: `© Fund “Talent and success”, 2018–{currentYear}`},
            },
        },
    },
    testing: {
        start_button: {
            took_part: {
                ru: {value: "Вы приняли участие в тестировании."},
                en: {value: "You took part in the test."},
            },
            ended: {
                ru: {value: "Тестирование завершено."},
                en: {value: "The test is over."},
            },
            stopped: {
                ru: {value: "Тестирование временно приостановлено."},
                en: {value: "The test is temporarily suspended."},
            },
            dont_took_part: {
                ru: {value: "Вы не принимали в нём участие."},
                en: {value: "You didn't take part in it."},
            },
            not_yet_started: {
                ru: {value: "Тестирование начнётся"},
                en: {value: "The test starts"},
            },
            not_yet_started_date: {
                ru: {
                    value: "Тестирование начнётся {date}",
                },
                en: {
                    value: "The test starts on {date}",
                },
            },
            not_yet_started_time: {
                ru: {
                    value: "в {date} на этой странице",
                },
                en: {
                    value: "at {date} on this page",
                },
            },
        },
        user_form: {
            go_btn: {
                ru: {value: "Перейти к тестированию"},
                en: {value: "To testing"},
            },
        },
        certificate: {
            back: {
                ru: {value: "Вернуться к тестированию"},
                en: {value: "Back to testing"},
            },
            share: {
                ru: {value: "Поделитесь результатом, который вы показали на тестировании"},
                en: {value: "Share your test result"},
            },
        },
        status_row: {
            ended: {
                ru: {value: "Завершилось"},
                en: {value: "It's over"},
            },
            not_yet_started: {
                ru: {value: "Будет доступно"},
                en: {value: "Will be available"},
            },
            not_yet_started_date: {
                ru: {
                    value: "c {date}",
                },
                en: {
                    value: "from {date}",
                },
            },
        },
        index: {
            copyright_CPM: {
                ru: {value: `© ГАОУ ДПО ЦПМ, 2018–{currentYear} {br} © Фонд «Талант и успех», 2018–{currentYear}`},
                en: {value: `© Center of Pedagogical Excellence, 2018–{currentYear} {br} © Fund “Talent and success”, 2018–{currentYear}`},
            },
            copyright_SIRIUS: {
                ru: {value: `© Фонд «Талант и успех», 2018–{currentYear}`},
                en: {value: `© Fund “Talent and success”, 2018–{currentYear}`},
            },
        },
    },
    common: {
        ...AuthRegisterTranslations,
        ...LoginPageTranslations,
        index_page: {
            are_you_ready: {
                ru: {value: "Готовы начать?"},
                en: {value: "Are you ready?"},
            },
            final_test: {
                ru: {value: "Итоговое тестирование"},
                en: {value: "The final test"},
            },
            tasks: {
                ru: {value: "Заданий"},
                en: {value: "Tasks"},
            },
            hand_in: {
                ru: {value: "Сдать до"},
                en: {value: "Submit before"},
            },
            over: {
                ru: {value: "Завершится"},
                en: {value: "Finish time"},
            },
            time: {
                ru: {value: "Время"},
                en: {value: "Time"},
            },
            time_elapsed: {
                ru: {value: "Время"},
                en: {value: "Time elapsed"},
            },
            duration: {
                ru: {value: "{number} мин"},
                en: {value: "{number} min"},
            },
            time_left: {
                ru: {value: "Осталось"},
                en: {value: "Time left"},
            },
        },
        user_info: {
            logout: {
                ru: {value: "Выйти"},
                en: {value: "Exit"},
            },
        },
        pre_start_popup: {
            time_for_contest: {
                ru: {value: "Решайте задачи в любом порядке. {br} У вас будет {number}"},
                en: {value: "You can solve the tasks in any order. {br} You'll have {number}"},
            },
            start: {
                ru: {value: "Начать"},
                en: {value: "Start"},
            },
            not_now: {
                ru: {value: "Не сейчас"},
                en: {value: "Not now"},
            },
            duration: {
                ru: {value: "{number} мин"},
                en: {value: "{number} min"},
            },
            hand_in: {
                ru: {value: "Сдать до"},
                en: {value: "Hand in before"},
            },
            tasks: {
                ru: {value: "Заданий"},
                en: {value: "Tasks"},
            },
            time: {
                ru: {value: "Время"},
                en: {value: "Time"},
            },
        },
        format_plural: {
            minutes: {
                ru: {one: 'минута', few: 'минуты', many: 'минут', other: 'минут'},
                en: {one: 'minute', other: 'minutes'},
            },
            seconds: {
                ru: {one: 'секунда', few: 'секунды', many: 'секунд', other: 'секунд'},
                en: {one: 'second', other: 'seconds'},
            },
            points: {
                ru: {one: 'балл', few: 'балла', many: 'баллов', other: 'баллов'},
                en: {one: 'point', other: 'points'},
            },
        },
        user_form: {
            school_name: {
                ru: {value: "Название школы"},
                en: {value: "The name of your school"},
            },
            grade: {
                ru: {value: "Класс, в котором учитесь сейчас"},
                en: {value: "The grade you're studying at"},
            },
            phone_number: {
                ru: {value: "Телефон"},
                en: {value: "Phone number"},
            },
            title: {
                ru: {value: "Укажите данные о себе"},
                en: {value: "Personal details"},
            },
            last_name: {
                ru: {value: "Фамилия"},
                en: {value: "Last name"},
            },
            name: {
                ru: {value: "Имя"},
                en: {value: "First name"},
            },
            patronymic: {
                ru: {value: "Отчество"},
                en: {value: "Middle name"},
            },
            participation_form: {
                ru: {value: "Выберите формат участия"},
                en: {value: "Choose your form of participation"},
            },
            email: {
                ru: {value: "Email"},
                en: {value: "Email"},
            },
            vosh_login: {
                ru: {value: "ВОШ-логин"},
                en: {value: "Vosh-login"},
            },
            vosh_login_error: {
                ru: {value: "Неверный ВОШ-логин. Попробуйте ещё раз"},
                en: {value: "Vosh-login incorrect. Try again"},
            },
            "snils": {
                ru: {value: "СНИЛС"},
                en: {value: "SNILS"},
            },
            "snils-opt": {
                ru: {value: "СНИЛС"},
                en: {value: "SNILS"},
            },
            "personal-data": {
                ru: {value: "Я согласен на "},
                en: {value: "I consent to the "},
            },
            "personal-data-link-label": {
                ru: {value: "обработку персональных данных"},
                en: {value: "processing of personal data"},
            },
            "error_snils_empty": {
                ru: {value: "СНИЛС не указан"},
                en: {value: "SNILS is empty"},
            },
            "error_snils_only_digits": {
                ru: {value: "СНИЛС должен содержать только цифры"},
                en: {value: "SNILS have to contains digits only"},
            },
            "error_snils_eleven": {
                ru: {value: "СНИЛС должен состоять ровно из 11 цифр"},
                en: {value: "SNILS have to be of 11 digits"},
            },
            "error_snils_sum": {
                ru: {value: "Контрольная сумма СНИЛС не совпадает"},
                en: {value: "Checksum of SNILS is not valid"},
            },
            "personal-data_error": {
                ru: {value: "Мы не сможем продолжить без согласия на обработку персональных данных"},
                en: {value: "Your agreement to provide personal data is required for further processing"},
            },
        },
        login_page: {
            header_CPM: {
                ru: {value: 'Образовательный Фонд «Талант и успех» {br} Центр Педагогического Мастерства'},
                en: {value: 'Educational Fund “Talent and success” {br} Center of Pedagogical Excellence'},
            },
            header_SIRIUS: {
                ru: {value: 'Образовательный Фонд «Талант и успех»'},
                en: {value: 'Educational Fund “Talent and success”'},
            },
            title: {
                ru: {value: "Олимпиады и\xa0тестирования"},
                en: {value: "Olympiads and\xa0tests"},
            },
            welcome: {
                ru: {value: "Добро пожаловать!"},
                en: {value: "Welcome!"},
            },
            participant_code_title: {
                ru: {value: "Код участника получите у\xa0ответственного лица"},
                en: {value: "Get your code of participant from the person in\xa0charge"},
            },
            participant_code: {
                ru: {value: "Код участника"},
                en: {value: "Code of participant"},
            },
            participant_code_enter: {
                ru: {value: "Введите код участника"},
                en: {value: "Insert your participant's code here"},
            },
            participant_code_error: {
                ru: {value: "Неверный код участника. Попробуйте ещё раз"},
                en: {value: "Your code of participant is incorrect. Try again"},
            },
            enter: {
                ru: {value: "Войти"},
                en: {value: "Enter"},
            },
        },
        start_button: {
            start: {
                ru: {value: "Начать"},
                en: {value: "Start"},
            },
            continue: {
                ru: {value: "Продолжить"},
                en: {value: "Continue"},
            },
            result: {
                ru: {value: "Результаты будут доступны на этой странице после проверки"},
                en: {value: "The results will be available on this page after checking"},
            },
            your_result: {
                ru: {value: "Ваш результат: {userScore} из {maxScore} {points}."},
                en: {value: "Your score is {userScore} out of {maxScore} {points}."},
            },
            total_participants: {
                ru: {value: "Всего участников: {number}."},
                en: {value: "Number of participants: {number}."},
            },
            future_results: {
                ru: {value: "Результаты будут доступны на этой странице после обработки ответов всех участников"},
                en: {value: "The results will be available here after processing of answers of all the participants"},
            },
            come_back_later: {
                ru: {value: "Зайдите позже, чтобы приступить к решению"},
                en: {value: "Come back later to start solving the problem"},
            },
            to_results: {
                ru: {value: "Перейти к результатам"},
                en: {value: "Get the results"},
            },
            document: {
                ru: {value: "Документ"},
                en: {value: "Document"},
            },
        },
        document: {
            default_title: {
                ru: {value: "Олимпиады и тестирования"},
                en: {value: "Olympiads and tests"},
            },
            noo_title: {
                ru: {value: "{title} — Сириус.Курсы"},
                en: {value: "{title} — Sirius.Courses"},
            },
            title: {
                ru: {value: "Олимпиады и тестирования — {title}"},
                en: {value: "Olympiads and tests — {title}"},
            },
        },
        certificate: {
            document_title: {
                ru: {value: "{text} — Олимпиады и тестирования"},
                en: {value: "{text} — Olympiads and tests"},
            },
            congratulations: {
                ru: {value: "Поздравляем! Получите {text}"},
                en: {value: "Congratulations! Get {text}"},
            },
            download_file: {
                ru: {value: "скачайте файл"},
                en: {value: "download the file"},
            },
            print_file: {
                ru: {value: "и распечатайте,"},
                en: {value: "and print it out,"},
            },
            copy: {
                ru: {value: "скопируйте"},
                en: {value: "copy"},
            },
            share_link: {
                ru: {value: "и отправьте ссылку, поделитесь в соцсетях или мессенджерах:"},
                en: {value: "and save the link, share in your social networks"},
            },
            permanent_link: {
                ru: {value: "Постоянная ссылка:"},
                en: {value: "Permanent link"},
            },
            download_certificate: {
                ru: {value: "Скачать файл"},
                en: {value: "Download file"},
            },
            copy_link: {
                ru: {value: "Копировать ссылку"},
                en: {value: "Copy link"},
            },
            header_title: {
                ru: {value: "Олимпиады и тестирования"},
                en: {value: "Olympiads and tests"},
            },
            verification: {
                ru: {value: "Подтверждение подлинности документа"},
                en: {value: "Verification of the document's authenticity"},
            },
        },
        profile_confirmation: {
            title: {
                ru: {value: "Мы заметили, что ответы сохраняются на разных вкладках браузера или нескольких устройствах"},
                en: {value: "We've noticed that answers are saved from different tabs of your browser or from different devices"},
            },
            caption: {
                ru: {value: "Обновите страницу, проверьте все ответы и продолжайте решать в одном месте"},
                en: {value: "Refresh the page, check up all the answers and keep solving in one place"},
            },
            refresh: {
                ru: {value: "Обновить страницу"},
                en: {value: "Refresh the page"},
            },
            is_not_me_title: {
                ru: {value: "Прежде, чем продолжить, убедитесь, что это вы"},
                en: {value: "Make sure you see your name before you continue"},
            },
            confirm: {
                ru: {value: "Да, всё верно"},
                en: {value: "Yes, everything is correct"},
            },
            typo: {
                ru: {value: "Вижу опечатку"},
                en: {value: "I see a typo"},
            },
            it_is_not_me: {
                ru: {value: "Это не я"},
                en: {value: "It's not me"},
            },
            mistake: {
                ru: {value: "Возможно, это ошибка"},
                en: {value: "It might be a mistake"},
            },
            do_not_solve: {
                ru: {value: "Не решайте олимпиаду. Обратитесь за помощью к ответственному лицу"},
                en: {value: "Don't solve the tasks and notify the person in charge"},
            },
            exit: {
                ru: {value: "Выйти"},
                en: {value: "Exit"},
            },
            check: {
                ru: {value: "Если вы не уверены, проверьте ещё раз"},
                en: {value: "Check again if you're not sure"},
            },
            back: {
                ru: {value: "Назад"},
                en: {value: "Back"},
            },
            keep_solving: {
                ru: {value: "Не переживайте. Продолжайте решать и сдайте работу"},
                en: {value: "Don't worry. Continue solving tasks and hand in your work"},
            },
            ask_for_help: {
                ru: {value: "После завершения обратитесь к ответственному лицу за помощью в исправлении ошибки"},
                en: {value: "After you finish ask the person in charge for help to correct the mistake"},
            },
            to_olympiad: {
                ru: {value: "В олимпиаду"},
                en: {value: "To olympiad"},
            },
            check_again: {
                ru: {value: "Если вы не уверены, проверьте ещё раз"},
                en: {value: "Check again if you're not sure"},
            },
            exception_408_title: {
                ru: {value: "Мы заметили, что произошёл разрыв интернет-соединения"},
                en: {value: "We noticed that your Internet connection was failed"},
            },
            exception_408_caption: {
                ru: {value: "Обновите страницу, проверьте все ответы и продолжайте решать задания"},
                en: {value: "Refresh the page, check your answers and continue to solve tasks"},
            },
            exception_502_title: {
                ru: {value: "Мы заметили, что произошёл сбой при проверке заданий"},
                en: {value: "We noticed that there was a failure when checking tasks"},
            },
            exception_502_caption: {
                ru: {value: "Обновите страницу, проверьте все ответы и продолжайте решать задания"},
                en: {value: "Refresh the page, check your answers and continue to solve tasks"},
            }
        },
        countdown: {
            duration: {
                ru: {value: "{hours} ч {minutes} мин "},
                en: {value: "{hours} h {minutes} min "},
            },
        },
        task: {
            out_of: {
                ru: {value: "{value_1} из {value_2}. "},
                en: {value: "{value_1} out of {value_2}. "},
            },
            save_button_title: {
                ru: {value: "Ответ можно сохранять несколько раз"},
                en: {value: "You can still change your answer after you save it"},
            },
            save_button_title_online: {
                ru: {value: "Можно отвечать несколько раз"},
                en: {value: "You can answer multiple times"},
            },
            resend_error: {
                ru: {value: "К сожалению, ваш последний ответ не сохранился."},
                en: {value: "Unfortunately, your last answer has not been saved."},
            },
            resend_try_again: {
                ru: {value: "Ответьте на задание ещё раз"},
                en: {value: "Answer the question again"},
            },

        },
        save_button: {
            resend: {
                ru: {value: "Заменить ответ"},
                en: {value: "Replace answer"},
            },
            next_try: {
                ru: {value: "Попробовать ещё"},
                en: {value: "Try again"},
            },
            next_task: {
                ru: {value: "Дальше"},
                en: {value: "Next"},
            },
            checking: {
                ru: {value: "Проверяем"},
                en: {value: "Checking..."},
            },
            give_answer: {
                ru: {value: "Ответить"},
                en: {value: "Check"},
            },
            has_solution_ok_go_next: {
                ru: {value: "Отлично! Переходите к следующей задаче"},
                en: {value: "Brilliant! Go to the next"},
            },
            has_solution_ok: {
                ru: {value: " Верно"},
                en: {value: " Correct"},
            },
            has_solution_partly: {
                ru: {value: " Частично верно."},
                en: {value: " Partly correct."},
            },
            has_solution_wrong: {
                ru: {value: " Пока неверно."},
                en: {value: " Incorrect so far."},
            },
            has_solution_again: {
                ru: {value: "Попробуйте ещё"},
                en: {value: "Try again"},
            },
            has_solution_pe:{
                ru: {value: "Неверный формат ответа."},
                en: {value: "Invalid response format."},
            },
            has_solution_notReady: {
                ru: {value: "Можно отвечать несколько раз."},
                en: {value: "You can answer multiple times."},
            },
            has_pending: {
                ru: {value: "Проверка ответа..."},
                en: {value: "Checking..."},
            },
            save_the_answer: {
                ru: {value: "Сохранить ответ"},
                en: {value: "Save the answer"},
            },
            answer_is_saved: {
                ru: {value: "Ответ сохранён"},
                en: {value: "The answer is saved"},
            },
            is_saving: {
                ru: {value: "Сохраняем ответ"},
                en: {value: "Saving answer"},
            },
            save: {
                ru: {value: "Сохранить"},
                en: {value: "Save"},
            },
        },
        task_selector: {
            total: {
                ru: {value: "Всего: {number}"},
                en: {value: "Total: {number}"},
            },
        },
        result_switch_button: {
            right_answer_programming: {
                ru: {value: "Эталонное решение"},
                en: {value: "Reference solution"},
            },
            right_answer: {
                ru: {value: "Показать правильный ответ"},
                en: {value: "Show the correct answer"},
            },
        },
        close_page: {
            title: {
                ru: {value: "Закрыть работу?"},
                en: {value: "Close work?"},
            },
            attention: {
                ru: {
                    not_saved: "Вы не сохранили ответы к заданиям:",
                },
                en: {
                    not_saved: "You  haven’t saved the answers to some tasks:",
                },
            },
            summary_attention: {
                ru: {
                    not_saved: "Все несохранённые ответы будут потеряны. {br} Вернитесь в задания и сохраните ответы.",
                },
                en: {
                    not_saved: "All unsaved answers will be lost. {br} Return to the tasks and save the answers.",
                },
            },
            return: {
                ru: {value: "Вернуться к заданиям"},
                en: {value: "Return"},
            },
            close: {
                ru: {value: "Закрыть"},
                en: {value: "Close"},
            },
        },
        result: {
            title: {
                ru: {value: "Завершить работу"},
                en: {value: "Complete the work"},
            },
            title_not_all: {
                ru: {value: "Вы сохранили ответы не для всех заданий"},
                en: {value: "You didn't enter the answers for all of the tasks"},
            },
            summary: {
                ru: {value: "После нажатия кнопки «Завершить работу» ответы {br} отправятся на проверку и внести изменения будет нельзя. {br} Результаты станут известны после обработки ответов"},
                en: {value: "After pressing the completing button your answers will be sent {br} to review and it will be impossible to make changes. {br} The results will be announced after answers are processed"},
            },
            summary_attention: {
                ru: {
                    not_saved:      "Вернитесь к заданиям и сохраните ответы, затем завершите работу.",
                },
                en: {
                    not_saved:      "Return to the tasks and save the answers, then complete the work.",
                },
            },
            title_all: {
                ru: {value: "Вы сохранили ответы для всех заданий"},
                en: {value: "You entered the answers for all of the tasks"},
            },
            send_for_examination: {
                ru: {value: "Завершить работу"},
                en: {value: "Complete the work"},
            },
            do_not_send: {
                ru: {value: "Вернуться к заданиям"},
                en: {value: "Keep working"},
            },
            duration: {
                ru: {value: "{number} мин"},
                en: {value: "{number} min"},
            },
            time: {
                ru: {value: "Время"},
                en: {value: "Time"},
            },
            answers: {
                ru: {value: "Ответов"},
                en: {value: "Answers"},
            },
            tasks: {
                ru: {value: "Заданий"},
                en: {value: "Tasks"},
            },
            time_left: {
                ru: {value: "{left} {number} {plural}"},
                en: {value: "{number} {plural} left"},
            },
            left: {
                ru: {one: 'Осталась', other: 'Осталось'},
                en: {one: 'left', other: 'left'},
            },
            attention: {
                ru: {
                    not_saved:      "Вы не сохранили ответы к заданиям",
                    not_answered:   "Вы не ответили на задания"
                },
                en: {
                    not_saved:      "You  haven’t saved the answers to some tasks",
                    not_answered:   "You haven’t entered the answers to some tasks"
                },
            }
        },
        verdict_text: {
            ok: {
                ru: {value: "Верно"},
                en: {value: "You answer is correct"},
            },
            wrong: {
                ru: {value: "Неверно"},
                en: {value: "Answer you entered is incorrect"},
            },
            partly: {
                ru: {value: "Частично верно"},
                en: {value: "Your answer is partly correct"},
            },
            not_ready: {
                ru: {value: "Задача на проверке. Зайдите позже"},
                en: {value: "Your answer has not been checked yet. Try this page later"},
            },
            annulled: {
                ru: {value: "Задание признано некорректным. Решение не оценивается"},
                en: {value: "The task is considered to be incorrect. The solutions aren't evaluated"},
            },
            no_verdict: {
                ru: {value: "Вы не отвечали на задание"},
                en: {value: "You didn't enter your answer for this task"},
            },
            notReady: {
                ru: {value: "Не проверено"},
                en: {value: "Not checked"},
            },
        },
        timeout_popup: {
            text: {
                ru: {value: "Работа отправлена на проверку. {br} Результаты станут известны после обработки ответов"},
                en: {value: "The work has been sent for review. {br} The results will be announced after answers are processed"},
            },
            remain: {
                ru: {value: "Осталось 0 минут"},
                en: {value: "0 minutes left"},
            },
            out_of_time: {
                ru: {value: "Время вышло"},
                en: {value: "There is no time left "},
            },
            answers: {
                ru: {value: "Ответов"},
                en: {value: "Answers"},
            },
            tasks: {
                ru: {value: "Заданий"},
                en: {value: "Tasks"},
            },
            time: {
                ru: {value: "Время"},
                en: {value: "Time"},
            },
            duration: {
                ru: {value: "{number} мин"},
                en: {value: "{number} min"},
            },
            got_it: {
                ru: {value: "Ясно"},
                en: {value: "Got it"},
            },
        },
        oversize_popup: {
            title: {
                ru: {value: "Уменьшите длину ответа, чтобы сохранить"},
                en: {value: "Reduce response length to save"},
            },
            description: {
                ru: {value: "Длина ответа превышает допустимую величину. Мы не можем сохранить его. Постарайтесь ответить короче"},
                en: {value: "The length of the response exceeds the allowable value. We cannot save it. Try to be short"},
            },
            got_it: {
                ru: {value: "Ясно"},
                en: {value: "Got it"},
            },
        },
        section: {
            forward: {
                ru: {value: "Вперёд"},
                en: {value: "Forward"},
            },
            next: {
                ru: {value: "Далее"},
                en: {value: "Next"},
            },
            time_left: {
                ru: {value: "Осталось {number} {plural}"},
                en: {value: "{number} {plural} left"},
            },
        },
        detailed_answer: {
            solution: {
                ru: {value: "Решение"},
                en: {value: "Solution"},
            },
            add_file: {
                ru: {value: "Прикрепите файл"},
                en: {value: "Add your file"},
            },
            cant_load: {
                ru: {value: "Невозможно загрузить файл"},
                en: {value: "It's impossible to upload the file"},
            },
            cant_load_size: {
                ru: {value: "Невозможно загрузить файл, попробуйте уменьшить его размер"},
                en: {value: "It's impossible to upload the file, try to reduce its size"},
            },
            comment_title: {
                ru: {value: "Комментарий к оценке"},
                en: {value: "Comment on the grade"},
            }
        },
        registration: {
            'has-errors': {
                ru: {value: "Проверьте данные в форме"},
                en: {value: "Check form data"},
            },
            back: {
                ru: {value: "Назад"},
                en: {value: "Back"},
            },
            success_message_atOnce: {
                ru: {value: "Мы отправили письмо с\xa0персональной ссылкой для\xa0выполнения заданий на\xa0почту {email}"},
                en: {value: "We sent an\xa0email with a\xa0personal link for\xa0completing tasks to\xa0{email}"},
            },
            success_message_delayed: {
                ru: {value: "На почту {email} отправлено письмо для\xa0подтверждения указанного электронного адреса и\xa0с\xa0информацией об\xa0участии в\xa0олимпиаде"},
                en: {value: "An email was sent to\xa0{email} to\xa0confirm the\xa0specified address and\xa0with\xa0information about participation in\xa0the\xa0olympiad"},
            },
        }
    },
    programming: {
        reviewStatus,
        reviewResult,
        "codeunit": {
            str: {
                ru: {value:"Строки:"},
                en: {value:"Strings:"},
            },
            sym: {
                ru: {value:"Символы:"},
                en: {value:"Symbols:"},
            },
            input: {
                ru: {value:"Входные данные"},
                en: {value:"Input data"},
            },
            output: {
                ru: {value:"Результат работы"},
                en: {value:"Output data"},
            },
            correct: {
                ru: {value:"Правильный ответ"},
                en: {value:"Correct answer"},
            },
            error: {
                ru: {value:"Поток ошибок"},
                en: {value:"Errors"},
            },
        },

        "tests-report-columns": {
            "number": {
                ru: {value:"№"},
                en: {value:"#"},
            },
            "result": {
                ru: {value:"Результаты"},
                en: {value:"Results"},
            },
            "time_s": {
                ru: {value:"Время, c"},
                en: {value:"Time, s"},
            },
            "memory_mb": {
                ru: {value:"Память, MB"},
                en: {value:"Memory, MB"},
            },
            "score": {
                ru: {value:"Балл"},
                en: {value:"Score"},
            },
        },
        tests: {
            header: {
                ru: {value:"Результат выполнения тестов"},
                en: {value:"Tests run results"},
            },
            "test-label": {
                ru: {value:"Тест"},
                en: {value:"Test"},
            },
            "timeSec-label": {
                ru: {value:"Время, c:"},
                en: {value:"Time, s:"},
            },
            "memoryMb-label": {
                ru: {value:"Память, MB:"},
                en: {value:"Memory, MB:"},
            },
        },
        verdict:{
            ok:{
                ru: {value: "Ответ верный"},
                en: {value: "Correct"},
            },
            wrong:{
                ru: {value: "Ответ неверный"},
                en: {value: "Incorrect"},
            },
            partly:{
                ru: {value: "Ответ частично верный"},
                en: {value: "Partly correct"},
            },
            annulled:{
                ru: {value: "задание признано некорректным и решение не оценивалось"},
                en: {value: "the task is incorrect and is not being evaluated"},
            },
            notReady:{
                ru: {value: "задача на проверке"},
                en: {value: "not ready"},
            },
            unscored:{
                ru: {value: "задача не оценивается"},
                en: {value: "the task is not evaluated"},
            },
            noneUnscored:{
                ru: {value: "на задачу не дан ответ и она не оценивается"},
                en: {value: "the task has not been answered and is not being evaluated"},
            },
            none:{
                ru: {value: "Вы не отвечали на задание"},
                en: {value: "the task has not been answered"},
            },
        },
        onlineverdicts:{
            hide: {
                ru: {value: "Свернуть"},
                en: {value: "Hide"},
            },
            verdictsScoreFrom: {
                ru: {value: "из"},
                en: {value: "from"},
            },
            solutionHeader: {
                ru: {value: "Решение"},
                en: {value: "Solution"},
            },
            solutionsNum: {
                ru: {one: 'решение', few: 'решения', many: 'решений', other: 'решений'},
                en: {one: 'solution', other: 'solutions'},
            },
            justnow: {
                ru: {value: "Только что"},
                en: {value: "Just now"},
            },
            solutionRetryButton: {
                ru: {value: "Понятно"},
                en: {value: "OK"},
            },
            solutionError_401: {
                ru: {value: "Ошибка загрузки"},
                en: {value: "Loading error"},
            },
            solutionError_403: {
                ru: {value: "В данной олимпиаде нельзя получать информацию по конкретному решению"},
                en: {value: "The contest don't provide information about this solution"},
            },
            solutionError_404: {
                ru: {value: "Запрошенное решение не найдено"},
                en: {value: "Solution not found"},
            },
        },
        codeeditor:{
            header: {
                ru: {value: "Решение"},
                en: {value: "Solution"},
            },
            header1: {
                ru: {value: "Код"},
                en: {value: "Code"},
            },
            filebtn: {
                ru: {value: "Код из файла"},
                en: {value: "Code from file"},
            },
            reset: {
                ru: {value: "Начать сначала"},
                en: {value: "Reset"},
            },
            hiddentxt: {
                ru: {value: "Текст решения скрыт"},
                en: {value: "Code is hidden"},
            }
        },
        io:{
            input: {
                ru: {value: "Ввод"},
                en: {value: "Input"},
            },
            output: {
                ru: {value: "Вывод"},
                en: {value: "Output"},
            },
        },
        limits:{
            header: {
                ru: {value: "Ограничения"},
                en: {value: "Constraints"},
            },
            realTime: {
                ru: {value: "Время выполнения"},
                en: {value: "Execution time (CPU)"},
            },
            time: {
                ru: {value: "Процессорное время"},
                en: {value: "Execution time (Wallclock)"},
            },
            maxMem: {
                ru: {value: "Память"},
                en: {value: "Memory"},
            },
            stackMem: {
                ru: {value: "Размер стека"},
                en: {value: "Stack size"},
            },
            compilationTime: {
                ru: {value: "Время на компиляцию"},
                en: {value: "Compilation time"},
            },
            charactersCount: {
                ru: {value: "Количество символов в исходном коде программы"},
                en: {value: "Maximum symbols"},
            },
        },
        examples: {
            title: {
                ru: {value: "Примеры"},
                en: {value: "Examples"},
            },
            input: {
                ru: {value: "Ввод"},
                en: {value: "Input"},
            },
            output: {
                ru: {value: "Вывод"},
                en: {value: "Output"},
            }
        }
    }
};

import React, {useEffect, useState}         from "react";
import {HBox, VBox}                         from "@sirius/ui-lib/src/blocks/Layout";
import {Button}                             from "@sirius/ui-lib/src/blocks/Controls";
import {useLocale}                          from "@sirius/ui-lib/src/subjects/Locale/useLocale";
import {ValidateFieldResult, Validation}    from "@sirius/ui-lib/src/blocks/SchemaField/@types";
import {SmtLocaleString}                    from "Smt/SmtLocaleBlock";
import {SchemaField, onInputSF$}            from "Smt/Auth/SchemaField";
import {UserFormProps}                      from "../@types";
import {UIOptions}                          from "./SchemaField/UIOptions";
import {UISchema}                           from "./SchemaField/UISchema";
import {block}                              from "./block";
import                                        './styles/index.less';

// @ts-ignore
const {result: userCodeForm}  = { ...require('./schema/user-code-form.yaml') };

const userFormTitleLocaleKey = {
    userDetailInfo:         'common.user_form.title',
    userParticipantCode:    'common.login_page.participant_code_title',
};

const userFormSubmitLocaleKey = {
    userDetailInfo:         `${CONFIG.currentInstance}.user_form.go_btn`,
    userParticipantCode:    'common.login_page.enter',
};


export const UserForm: React.FC<UserFormProps> = (
    {
        schema: _schema,
        options= {},
        loginStage,
        onRegister,
        hasErrors: _hasErrors= false,
    }
) => {
    const locale    = useLocale();
    const schema    = _schema ?? userCodeForm;

    const [validation,   setValidation  ] = useState<Validation>();
    const [onValidate,   setOnValidate  ] = useState<any>(void(0));
    const [valid,        setValid       ] = useState<boolean>(false);
    const [errors,       setErrors      ] = useState<any[]>([]);
    const [value,        setValue       ] = useState<any>(void(0));
    const [hasErrors,    setHasErrors   ] = useState<boolean>(_hasErrors);
    const [isChanged,    setIsChanged   ] = useState<boolean>(false);

    useEffect(() => {
            const onInputSubj = onInputSF$.subscribe( () => setIsChanged(true) );

            return () => {
                onInputSubj && onInputSubj.unsubscribe();
            };
        }, []
    );

    const resetForm = (initVal?: any) => {
        setValue(initVal);
        setValid(false);
        setIsChanged(false);
        setErrors([]);
    };

    useEffect(
        () => resetForm(),
        [loginStage]
    );

    const onValidateHandler = (validateResult: ValidateFieldResult = {}) => {
        const {isValid, validation, errors} = validateResult;
        setValidation(validation);
        setErrors(errors);
        setValid(isValid);
    };

    useEffect(
        ()=>{
            const hasOnValidate = { onValidate: isChanged ? onValidateHandler : void(0) };
            setOnValidate(hasOnValidate);
        },
        [isChanged]
    );

    useEffect(
        ()=>{
            isChanged && errors?.length === 0 && setHasErrors(false);
        },
        [isChanged, errors]
    );


    const onRegisterHandler = () =>
        valid && onRegister(value)
    ;

    return (
                <VBox className={block}>
                    <HBox className = {block.el('form-title')}>
                        <SmtLocaleString k={userFormTitleLocaleKey[loginStage]}/>
                    </HBox>
                    {   schema
                        ?   <>
                                <SchemaField
                                    uioptions           = {{...UIOptions, options, locale}}
                                    uischema            = {UISchema}
                                    schema              = {schema}
                                    data                = {value}
                                    onChange            = {setValue}
                                    block               = {block}
                                    validation          = {validation}
                                    {...onValidate}
                                />
                                <Button
                                    className = {block.el('register-btn')}
                                    mode      = "primary"
                                    form      = "rounded"
                                    disabled  = {!valid}
                                    onAction  = {onRegisterHandler}
                                >
                                    <SmtLocaleString k={userFormSubmitLocaleKey[loginStage]}/>
                                </Button>
                                { hasErrors  &&
                                    <HBox className = {block.el('has-errors-warning')}>
                                        <SmtLocaleString k={`common.registration.has-errors`}/>
                                    </HBox>
                                }
                            </>
                        :   <div>no schema</div>
                    }
                </VBox>
    )
}
